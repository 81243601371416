import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import HeroError from "../components/General/HeroError";
import Footer from "../components/Context/Footer";

const NotFoundPage = () => {
    const theme = "dark";

    return (
        <Layout>
            <SEO title="Page not found" />
            <Header theme={theme} />
            <HeroError title={"Page not found"} tagline={"<p>We’re sorry, the page you are looking for was not found.</p>"} theme={theme} linkText="Back to L’Atelier home" linkUrl="/" internal={true} />
            <Footer theme={theme} />
        </Layout>
    );
};


export default NotFoundPage;
