import React from "react";
import styled from "@emotion/styled";
import typography from "../../styles/typography";
import Container from "../Context/Container";
import Section from "../Context/Section";
import { Link } from "gatsby";

const Title = styled.h1`
    ${typography.h2Hero};

    margin-bottom: 16px;

    @media screen and (min-width: 1800px) {
        margin-bottom: 24px;
        max-width: 987px;
    }
`;

const Standard = styled.div`
    ${typography.standard};
    ${typography.RTESpacing};
    p{
        font-weight: normal;

        @media screen and (min-width: 768px) {
            max-width: 476px;
        }

        @media screen and (min-width: 1200px) {
            max-width: 785px;
        }

        @media screen and (min-width: 1800px) {
            max-width: 987px;
        }
    }
`;

const Navigate = styled.div`
    ${typography.standard};
    margin-top: 24px;

    p{
        font-weight: normal;

        @media screen and (min-width: 768px) {
            max-width: 476px;
        }

        @media screen and (min-width: 1200px) {
            max-width: 785px;
        }

        @media screen and (min-width: 1800px) {
            max-width: 987px;
        }
    }
`;

const HeroError = ({title, tagline, linkText, linkUrl, internal, noFlex, shortenedPaddingTop, theme}) => {
    return (
        <Section
            paddingS={`${shortenedPaddingTop ? "40px" : "133px"} 0 40px`}
            paddingM={`${shortenedPaddingTop ? "48px" : "203px"} 0 48px`}
            paddingL={`${shortenedPaddingTop ? "96px" : "238px"} 0 96px`}
            paddingXL={`${shortenedPaddingTop ? "96px" : "258px"} 0 56px`}
            paddingXXL={`${shortenedPaddingTop ? "96px" : "278px"} 0 76px`}
            bg="white"
            fg="dark"
            theme={theme}
            flexGrow="1"
            noFlex={noFlex}
        >
            <Container>
                <Title>
                    {title}
                </Title>
                <Standard dangerouslySetInnerHTML={{ __html: tagline }}/>
                {linkUrl && linkText && (
                    <Navigate>
                        <p>
                            {internal ? (
                                <Link to={linkUrl}>{linkText}</Link>
                            ) : (
                                <a href={linkUrl} target="_blank" rel="noopener noreferrer">{linkText}</a>
                            )}
                        </p>
                    </Navigate>
                )}
            </Container>
        </Section>
    );
};

export default HeroError;
